/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Endpoint,
  HttpMethod,
  RecaptchaClientType,
  RecaptchaVersion,
  _performApiRequest,
  _addTidIfNecessary
} from '../index';
import { Auth } from '../../model/public_types';

interface GetRecaptchaParamResponse {
  recaptchaSiteKey?: string;
}

export async function getRecaptchaParams(auth: Auth): Promise<string> {
  return (
    (
      await _performApiRequest<void, GetRecaptchaParamResponse>(
        auth,
        HttpMethod.GET,
        Endpoint.GET_RECAPTCHA_PARAM
      )
    ).recaptchaSiteKey || ''
  );
}

// The following functions are for reCAPTCHA enterprise integration.
interface GetRecaptchaConfigRequest {
  tenantId?: string;
  clientType?: RecaptchaClientType;
  version?: RecaptchaVersion;
}

export interface RecaptchaEnforcementProviderState {
  provider: string;
  enforcementState: string;
}

export interface GetRecaptchaConfigResponse {
  recaptchaKey: string;
  recaptchaEnforcementState: RecaptchaEnforcementProviderState[];
}

export async function getRecaptchaConfig(
  auth: Auth,
  request: GetRecaptchaConfigRequest
): Promise<GetRecaptchaConfigResponse> {
  return _performApiRequest<
    GetRecaptchaConfigRequest,
    GetRecaptchaConfigResponse
  >(
    auth,
    HttpMethod.GET,
    Endpoint.GET_RECAPTCHA_CONFIG,
    _addTidIfNecessary(auth, request)
  );
}
